<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="关键字搜索"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in USER_ACCOUNT_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">用户账户数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="名称"
        align="center"
        prop="name"
      ></el-table-column>

      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定修改该用户状态吗？"
            @confirm="checkUserStatus(scope.row)"
            style="margin: 0 8px;"
          >
            <el-button slot="reference" type="success" size="mini"
              >切换用户状态</el-button
            >
          </el-popconfirm>

          <el-button
            size="mini"
            @click="checkAccountLogVisible(true, scope.row)"
            >查询账户流水</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    账户流水-->
    <el-dialog
      title="账户流水"
      :visible.sync="accountLogVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <el-table :data="accountLogList" height="250" border style="width: 100%">
        <el-table-column prop="date" label="日期" width="180">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkAccountLogVisible(false, {})"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { formatApiDate } from "@/utils/utils";
import { USER_ACCOUNT_STATUS } from "@/utils/enumUtile";
import {
  getUserAccountList,
  switchUserAccountStatus,
  getUserAccountLogList
} from "@/api/financial";

export default {
  computed: {
    USER_ACCOUNT_STATUS() {
      return USER_ACCOUNT_STATUS;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "", // 系统支付订单号
        status: "" //状态:1-正常，2-冻结
      },
      accountLogVisible: false, // 查看账户流水
      accountLogObj: false, // 要查看账户流水
      accountLogList: [] // 账户流水
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询设备功能列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getUserAccountList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换
     * @param row
     */
    checkUserStatus(row) {
      console.log("row", row);
      const params = {
        id: row.id,
        status: row.status === 1 ? 2 : 1
      };
      switchUserAccountStatus(params).then(resp => {
        if (resp.code === 0) {
          this.$notify({
            title: "成功",
            message: resp.data,
            type: "success",
            duration: 2000
          });
        } else {
          this.$notify({
            title: "提示",
            message: resp.data,
            type: "warning",
            duration: 2000
          });
        }
      });
    },
    /**
     * 账户流水
     * @param status
     * @param editObj
     */
    checkAccountLogVisible(status, editObj = {}) {
      this.accountLogObj = editObj;
      if (status) {
        this.getUserAccountLogList();
      } else {
        this.accountLogList = [];
      }
      this.accountLogVisible = status;
    },
    /**
     * 查询账户流水
     */
    getUserAccountLogList() {
      const accountLogObj = this.accountLogObj;
      const params = {
        accountId: accountLogObj.id, // 账户ID
        type: "" //类型：1-总余额，2-可用余额，3-冻结余额
      };
      getUserAccountLogList(params).then(resp => {
        console.log("resp", resp);
        this.accountLogList = resp.data;
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
