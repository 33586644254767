import request from "@/utils/request";

// ========================== 设备支付流水 start =====================================================

/**
 * 申请退款
 * @param params
 * @returns {*}
 */
export function refundApply(params) {
  return request({
    url: "/api/backend/finance/devices/refundApply",
    method: "post",
    data: params
  });
}

/**
 * 查询支付流水
 * @param params
 * @returns {*}
 */
export function getPayOrderList(params) {
  return request({
    url: "/api/backend/finance/devices/getPayOrderList",
    method: "get",
    params
  });
}

// ========================== 设备支付流水 end =====================================================

// ========================== 设备退款流水 start =====================================================

/**
 * 查询退款流水
 * @param params
 * @returns {*}
 */
export function getRefundOrderList(params) {
  return request({
    url: "/api/backend/finance/devices/getRefundOrderList",
    method: "get",
    params
  });
}

// ========================== 设备退款流水 end =====================================================

// ========================== 用户账户 start =====================================================

/**
 * 查询用户账户
 * @param params
 * @returns {*}
 */
export function getUserAccountList(params) {
  return request({
    url: "/api/backend/finance/getUserAccountList",
    method: "get",
    params
  });
}
/**
 * 查询账户流水
 * @param params
 * @returns {*}
 */
export function getUserAccountLogList(params) {
  return request({
    url: "/api/backend/finance/getUserAccountLogList",
    method: "get",
    params
  });
}

/**
 * 切换账户状态
 * @param params
 * @returns {*}
 */
export function switchUserAccountStatus(params) {
  return request({
    url: "/api/backend/finance/switchUserAccountStatus",
    method: "post",
    data: params
  });
}
// ========================== 用户账户 end =====================================================

// ========================== 用户积分 start =====================================================
/**
 * 查询用户积分
 * @param params
 * @returns {*}
 */
export function getUserIntegralList(params) {
  return request({
    url: "/api/backend/finance/getUserIntegralList",
    method: "post",
    data: params
  });
}
/**
 * 查询积分流水
 * @param params
 * @returns {*}
 */
export function getUserIntegralLogList(params) {
  return request({
    url: "/api/backend/finance/getUserIntegralLogList",
    method: "post",
    data: params
  });
}
// ========================== 用户积分 end =====================================================
